@import url("https://fonts.googleapis.com/css2?family=League+Spartan:wght@200;400;500;700;900&display=swap");
@import "./globals/variables.scss";
@import "./globals/fonts.scss";

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: var(--League-spartan);
}

body,
#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: var(--background);
}

#root {
  width: 100%;
  margin-bottom: 10px;
}

main {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

::-webkit-scrollbar {
  width: 7px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
